import React, { useContext } from 'react';
import { Context } from 'store/createContext';
import { Container } from './nav.css';
import ZoomLink from '../../zoom-link';

const Nav = () => {
  const store = useContext(Context);

  return (
    <Container>
      <ul>{store.vip && <div></div>}</ul>
    </Container>
  );
};

export default Nav;
