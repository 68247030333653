import styled from 'styled-components';
import { offWhite, primaryText } from 'constants/theme';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  background-color: ${primaryText};

  h1 {
    font-size: 3.8rem;
  }

  a {
    transition: color 0.2s ease;
    text-decoration: none;
  }
`;
